var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-0 px-3 px-md-12", attrs: { fluid: "" } },
    [
      _c("SelectPrizeModal", { ref: "select_prize_modal" }),
      _c("span", { staticClass: "pl-5 pl-md-3 section-title" }, [
        _vm._v(_vm._s(_vm.$t("views.levels.prize.title")))
      ]),
      _c(
        "v-card",
        { staticClass: "my-4 mx-2 px-7 pt-6 pt-md-8 pb-6 elevation-0" },
        [
          _vm.loading
            ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
            : _vm._e(),
          _c("v-card-text", { staticClass: "pa-0 pb-12 pb-md-0" }, [
            _c("div", { staticClass: "mb-md-0 primary--text card-title" }, [
              _vm._v(_vm._s(_vm.$t("views.levels.prize.subtitle")))
            ]),
            _vm.$vuetify.breakpoint.mdAndUp
              ? _c(
                  "div",
                  { staticClass: "secondary_font--text card-subtitle" },
                  [_vm._v(_vm._s(_vm.$t("views.levels.prize.description")))]
                )
              : _vm._e()
          ]),
          !_vm.level || !_vm.level.prize
            ? _c(
                "v-alert",
                { staticClass: "mt-8 no-prize-alert", attrs: { type: "info" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("views.levels.prize.no_selected_prize")) +
                      " "
                  ),
                  _c("br"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-3",
                      attrs: { outlined: "" },
                      on: { click: _vm.openSelectPrizeModal }
                    },
                    [_vm._v(_vm._s(_vm.$t("views.levels.prize.select_prize")))]
                  )
                ],
                1
              )
            : !_vm.loading
            ? _c(
                "div",
                [
                  _c("PrizeCard", {
                    staticClass: "mt-0 mt-md-6 mb-2 mb-md-4",
                    attrs: { prize: _vm.level.prize, hidePrice: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "absolute",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mt-1 mr-1 background",
                                    attrs: {
                                      icon: "",
                                      width: "30px",
                                      height: "30px"
                                    },
                                    on: { click: _vm.removePrize }
                                  },
                                  [
                                    _c("v-icon", { attrs: { "x-small": "" } }, [
                                      _vm._v("$cp_trash")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "actions",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "indigo accent-3",
                                  block: "",
                                  depressed: "",
                                  outlined: ""
                                },
                                on: { click: _vm.openSelectPrizeModal }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("views.levels.prize.change_prize")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }