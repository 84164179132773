var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        persistent: ""
      },
      on: { cancel: _vm.cancel },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function() {
            return [
              _c("v-icon", { attrs: { color: "indigo accent-2" } }, [
                _vm._v("fas fa-gift")
              ])
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _c("PrizeIterator", {
        staticClass: "prize-iterator",
        attrs: {
          fetchFunction: _vm.fetchPrizes,
          colProps: { cols: 12, sm: 6, md: 4, lg: 3 }
        },
        on: { error: _vm.errorHandler },
        scopedSlots: _vm._u([
          {
            key: "card-actions",
            fn: function(ref) {
              var prize = ref.prize
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "indigo accent-3",
                      block: "",
                      depressed: "",
                      outlined: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectPrize(prize)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("actions.select")) + " ")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }