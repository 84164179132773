<template>
    <v-container fluid class="py-0 px-3 px-md-12">
        <SelectPrizeModal ref="select_prize_modal" />

        <span class="pl-5 pl-md-3 section-title">{{ $t('views.levels.prize.title') }}</span>
        <v-card class="my-4 mx-2 px-7 pt-6 pt-md-8 pb-6 elevation-0">
            <v-progress-linear v-if="loading" indeterminate />

            <v-card-text class="pa-0 pb-12 pb-md-0">
                <div class="mb-md-0 primary--text card-title">{{ $t('views.levels.prize.subtitle') }}</div>
                <div v-if="$vuetify.breakpoint.mdAndUp" class="secondary_font--text card-subtitle">{{ $t('views.levels.prize.description') }}</div>
            </v-card-text>

            <v-alert v-if="!level || !level.prize" type="info" class="mt-8 no-prize-alert">
                {{ $t('views.levels.prize.no_selected_prize') }}
                <br>
                <v-btn @click="openSelectPrizeModal" class="mt-3" outlined>{{ $t('views.levels.prize.select_prize') }}</v-btn>
            </v-alert>

            <div v-else-if="!loading">
                <PrizeCard :prize="level.prize" class="mt-0 mt-md-6 mb-2 mb-md-4" hidePrice>
                    <template #absolute>
                        <div class="d-flex">
                            <v-btn icon width="30px" height="30px" class="mt-1 mr-1 background" @click="removePrize">
                                <v-icon x-small>$cp_trash</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <template #actions>
                        <v-btn
                            color="indigo accent-3"
                            block depressed outlined
                            @click="openSelectPrizeModal"
                        >
                            {{ $t('views.levels.prize.change_prize') }}
                        </v-btn>
                    </template>
                </PrizeCard>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters }       from 'vuex'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import SelectPrizeModal     from '@/components/modals/SelectPrizeModal'
import PrizeCard            from '@/shared/components/cards/PrizeCard'

export default {
    name: 'PrizeLevelDetails',
    props: {
        levelId: {
            type: String,
            required: true,
        },
    },
    mixins: [ HasErrorHandlerMixin ],
    components: { SelectPrizeModal, PrizeCard },
    data: vm => ({
        loading: false,
        level  : null,
    }),
    async created() {
        await this.fetchLevel()
    },
    methods: {
        async fetchLevel() {
            this.loading = true
            this.level   = this.$store.getters['level/getLevelById'](this.levelId)
            this.loading = false

            if (!this.level) {
                this.$router.push({ name: 'levels.list', params: { errType: 'error_get' } })
                return
            }
        },
        async openSelectPrizeModal() {
            let selectedPrize = await this.$refs.select_prize_modal.open()

            if (!selectedPrize)
                return

            this.loading = true
            this.level.prize = selectedPrize
            let res = await this.level.update()
                .catch(this.errorHandler)
            this.loading = false

            if (!res)
                return

            this.$bus.$emit('message', this.$t('views.levels.prize.success'), 'success')
        },
        async removePrize() {
            this.loading = true
            this.level.prize = { id: null }
            let res = await this.level.update()
                .catch(this.errorHandler)
            this.loading = false

            if (!res)
                return

            this.$bus.$emit('message', this.$t('views.levels.prize.remove_success'), 'success')
        },
    },
    computed: {
        ...mapGetters({
            levels: 'level/levels',
        }),
    },
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped), .no-prize-alert {
    border-radius: 8px !important;
}

.card-title, .card-subtitle {
    height: 20px;
}

.card-title {
    font-size: 16px;
    font-weight: bold;
}

.card-subtitle {
    font-size: 14px;
}
</style>