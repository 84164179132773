<template>
    <BaseModal ref="modal"
        @cancel="cancel"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        persistent
    >
        <template #icon>
            <v-icon color="indigo accent-2">fas fa-gift</v-icon>
        </template>

        <PrizeIterator
            :fetchFunction="fetchPrizes"
            @error="errorHandler"
            :colProps="{ cols: 12, sm: 6, md: 4, lg: 3 }"
            class="prize-iterator"
        >
            <template #card-actions="{ prize }">
                <v-btn
                    color="indigo accent-3"
                    block depressed outlined
                    @click="selectPrize(prize)"
                >
                    {{ $t('actions.select') }}
                </v-btn>
            </template>
        </PrizeIterator>

    </BaseModal>
</template>

<script>
import BaseModal      from '@/components/modals/BaseModal'
import PrizeIterator  from '@/shared/components/iterators/PrizeIterator'
import BaseModalMixin from './BaseModalMixin'
import Prize          from '@/models/Prize'

export default {
    name: 'SelectPrizeModal',
    components: { BaseModal, PrizeIterator },
    mixins: [ BaseModalMixin ],
    data: vm => ({
        title: vm.$t('modals.SelectPrizeModal.title'),
    }),
    methods: {
        selectPrize(prize) {
            this.resolveValue = prize
            this.confirm()
        },

        async fetchPrizes(pagination = {}) {
            this.loading = true
            let res = await Prize.list({ params: pagination })
                .catch(this.errorHandler)
            this.loading = false

            return res || []
        },
    },
    computed: {
        tabs() {
            return [
                { title: this.$t('views.prizes.tabs.all'), disabled: false },
            ]
        },
        computedConfirmButton() {
            return {
                hide: true,
            }
        },
        computedCancelButton() {
            return {}
        },
    },
}
</script>

<style scoped lang="scss">
.prize-iterator::v-deep .v-tab.v-tab, .prize-iterator::v-deep .spacer {
    background-color: var(--v-sections-base);
}
</style>